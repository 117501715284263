import {
    ChangeDetectionStrategy,
    ChangeDetectorRef,
    Component,
    Input,
    OnChanges,
    OnInit,
    ViewChild
} from '@angular/core';
import { PageEvent } from '@angular/material/paginator';
import {MatSort, Sort} from '@angular/material/sort';
import { NominationService } from '@app/nomination-management/services/nomination.service';
import { SortSource } from '@app/shared/models/settings/sort-source.enum';
import { SettingsService } from '@app/shared/services';
import { NavbarService } from '@app/user-dashboard/components/navbar/navbar.service';
import { UserService } from '../../../../user-management/services/user.service';
import { NominationComponent } from '../nomination.component';
import {ESCAPE} from '@angular/cdk/keycodes';
import {MatTableDataSource} from '@angular/material/table';

@Component({
    selector: 'app-nomination-similar-list',
    templateUrl: 'nomination-similar-list.component.view.html',
    styleUrls: ['./nomination-similar-list.component.scss'],
    changeDetection: ChangeDetectionStrategy.OnPush
})
export class NominationSimilarListComponent implements OnInit, OnChanges {
    public displayedColumns = ['description', 'score', 'vote'];

    public dataSource = new MatTableDataSource<any>();
    @Input() nomination: any;
    @Input() voteEnabled: boolean;

    // Add MatSort as a ViewChild
    @ViewChild(MatSort) sort: MatSort;

    ngOnInit() {
        this.updateDataSource();
    }

    ngOnChanges() {
        this.updateDataSource();
    }

    private updateDataSource() {
        if (!this.voteEnabled) {
            this.displayedColumns = this.displayedColumns.filter(column => column !== 'vote');
        }
        if (this.nomination?.aiScoreSuggestion?.similarNominations) {
            this.dataSource.data = this.nomination.aiScoreSuggestion.similarNominations;
            this.dataSource.sort = this.sort; // Connect MatSort to the dataSource
        }
    }
}
