<div mat-dialog-content class="border-start border-primary bg-light p-3 mb-3 rounded">
    <p translate class="m-0 text-dark">
        nominations.ai.dialog.description
    </p>
    <p class="mt-2 m-0 text-dark">
        <strong translate>nominations.ai.dialog.similarNominations</strong> <span translate >nominations.ai.dialog.similarNominationsDescription</span>
    </p>
</div>

<h3 class="mat-card-header-text mat-card-header-text-inner">
                            <span translate>
                                nominations.headings.feedbacks
                            </span>
    <span class="badge" [ngClass]="requiredAmountOfFeedbackGiven >= nomination.voteStatistics.requiredFeedbackCount ? 'badge-success' : 'badge-danger'">
  {{ requiredAmountOfFeedbackGiven }}/{{ nomination.voteStatistics.requiredFeedbackCount }}
</span>
</h3>

<!-- Similar Nominations List -->
<div mat-dialog-content>
    <app-nomination-similar-list [voteEnabled] = true [nomination]="nomination"></app-nomination-similar-list>
</div>

<!-- Action Buttons with User Instructions -->
<div mat-dialog-actions class="d-flex justify-content-end">
    <!-- Cancel Request Button -->
    <button mat-button color="secondary" [mat-dialog-close]="false" class="btn btn-secondary">
        <span translate>nominations.actions.cancelRequest</span>
    </button>

    <!-- Send Request Button -->
    <button mat-raised-button color="primary" (click)="onButtonClick()" class="btn btn-primary ms-2">
        <span translate>nominations.actions.sendRequest</span>
    </button>
</div>
